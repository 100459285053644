import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Our friends and ‘teammates’ at D2D published a newsletter called The Connection…the goal of which is to bring together leaders from the financial services industry, academia, community organizations, non-profits, and the policy world to explore making saving fun in a variety of contexts.`}</p>
    <p>{`We're excited to have a nice PayPerks case study written up on Page 27 on the report, which you can find here: `}<a parentName="p" {...{
        "href": "http://www.d2dfund.org/files/publications/PlayingTSG_Report_2012_FINAL.pdf"
      }}>{`http://www.d2dfund.org/files/publications/PlayingTSG_Report_2012_FINAL.pdf`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      